import { dispatchAuthTokenChangeEvent } from "../events/authTokenEvents";
export var authTokenLocalStorageKey = "kote:api-auth-token";
export var getAuthToken = function () {
    if (typeof window === "undefined") {
        return null;
    }
    var token = window.localStorage.getItem(authTokenLocalStorageKey);
    if (token && !verifyAuthToken(token)) {
        console.warn("Authentication JWT has expired");
        setAuthToken(null);
        return null;
    }
    return token;
};
export var logout = function () { return setAuthToken(null); };
// Updates the auth token, if the token is null it's removed
export var setAuthToken = function (token) {
    if (typeof window === "undefined") {
        return;
    }
    if (token) {
        window.localStorage.setItem(authTokenLocalStorageKey, token);
    }
    else {
        window.localStorage.removeItem(authTokenLocalStorageKey);
    }
    window.dispatchEvent(new StorageEvent("storage", {
        storageArea: window.localStorage,
        key: authTokenLocalStorageKey,
    }));
    dispatchAuthTokenChangeEvent({ token: token });
};
export var getTokenPayload = function (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
};
// Checks that the auth token hasn't expired
export var verifyAuthToken = function (token) {
    try {
        var exp = getTokenPayload(token).exp;
        return exp ? exp * 1000 > Date.now() : true;
    }
    catch (error) {
        console.error("Failed to decode authentication JWT");
        console.error(error);
        return false;
    }
};
export var getAuthTokenAddress = function () {
    var token = getAuthToken();
    if (!token) {
        return null;
    }
    try {
        var sub = getTokenPayload(token).sub;
        if (!sub) {
            console.error("Authentication JWT is missing 'sub' (the subject)");
            return null;
        }
        return sub;
    }
    catch (error) {
        console.error("Failed to decode authentication JWT");
        console.error(error);
        return null;
    }
};
