import { useEffect } from "react";
import { useBridgeSocket } from "../contexts/BridgeSocket";
export var useBridgeSocketListener = function (eventName, listener) {
    var socket = useBridgeSocket().socket;
    useEffect(function () {
        if (socket) {
            socket.on(eventName, listener);
            return function () {
                socket.off(eventName, listener);
            };
        }
    }, [socket, listener]);
};
