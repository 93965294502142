var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useMemo, useState } from "react";
import { useBridgeSocket } from "../contexts/BridgeSocket";
import { useBridgeSocketListener } from "./useBridgeSocketListener";
import BridgeAbi from "../abis/bridge.json";
import { useWriteContract, useWaitForTransactionReceipt, } from "wagmi";
import { contracts } from "@/walletConfig";
import { WithdrawStatus } from "../contexts/BridgeSocket";
import { useModalContext } from "@/contexts/ModalContext";
export var useWithdraw = function () {
    var _a = useWithdrawSocket(), withdraw = _a.withdraw, withdrawls = _a.withdrawls, socketError = _a.error, withdrawStatus = _a.withdrawStatus, setWithdrawStatus = _a.setWithdrawStatus;
    var _b = useWithdrawContract(withdrawls), withdrawItemsContractCall = _b.withdrawItemsContractCall, isSuccess = _b.isSuccess;
    useEffect(function () {
        console.log("withdrawStatus changed:", withdrawStatus);
    }, [withdrawStatus]);
    useEffect(function () {
        if (withdrawStatus === WithdrawStatus.RECEIVED) {
            withdrawItemsContractCall();
            setWithdrawStatus(WithdrawStatus.IDLE);
        }
    }, [withdrawStatus, withdrawls]);
    return {
        withdraw: withdraw,
        withdrawls: withdrawls,
        isSuccess: isSuccess,
    };
};
var useWithdrawSocket = function () {
    var _a = useState(false), isSuccess = _a[0], setIsSuccess = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useBridgeSocket(), withdraw = _c.withdraw, fetchItems = _c.fetchItems, withdrawls = _c.withdrawls, setWithdrawls = _c.setWithdrawls, withdrawStatus = _c.withdrawStatus, setWithdrawStatus = _c.setWithdrawStatus;
    var isBridgeGearModalOpen = useModalContext().isBridgeGearModalOpen;
    useBridgeSocketListener("withdrawls", function (payload) {
        console.log("withdrawls event received", payload);
        if (payload.success === false) {
            setError(new Error("".concat(payload.message)));
        }
        else {
            setWithdrawls(payload.withdrawls);
            setIsSuccess(true);
            setError(null);
            if (withdrawStatus === WithdrawStatus.CLICKED) {
                setWithdrawStatus(WithdrawStatus.RECEIVED);
            }
        }
    });
    useEffect(function () {
        if (!isBridgeGearModalOpen) {
            setIsSuccess(false);
            setWithdrawStatus(WithdrawStatus.IDLE); //make sure if modal closes, status reset to IDLE
        }
    }, [isBridgeGearModalOpen]);
    useEffect(function () {
        if (isSuccess) {
            fetchItems();
        }
    }, [isSuccess]);
    useEffect(function () {
        if (error) {
            console.error("Withdraw Error:");
            console.error(error);
        }
    }, [error]);
    return {
        withdrawls: withdrawls,
        withdraw: withdraw,
        isSuccess: isSuccess,
        isError: !!error,
        error: error,
        withdrawStatus: withdrawStatus,
        setWithdrawStatus: setWithdrawStatus,
    };
};
var useWithdrawContract = function (withdrawls) {
    var _a = useWriteContract(), writeContract = _a.writeContract, hash = _a.data, isPending = _a.isPending;
    var args = useMemo(function () {
        var ids = [];
        var amounts = [];
        var nonces = [];
        var signatures = [];
        withdrawls === null || withdrawls === void 0 ? void 0 : withdrawls.forEach(function (_a) {
            var signature = _a.sig, withdrawl = _a.withdrawl;
            var amount = withdrawl.amount, nonce = withdrawl.nonce, fullGearId = withdrawl.fullGearId;
            console.log("HERE IS WITHDRAWAL OBJECT", withdrawl);
            if (amount > 0) {
                ids.push(withdrawl.id); // Use fullGearId if available, otherwise fallback to id
                amounts.push(amount);
                nonces.push(nonce);
                signatures.push(signature);
            }
        });
        return [ids, amounts, nonces, signatures];
    }, [withdrawls]);
    var withdrawItemsContractCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var transactionResponse, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    console.log("Calling contract with args:", args);
                    return [4 /*yield*/, writeContract({
                            address: contracts.bridge,
                            abi: BridgeAbi,
                            functionName: "withdrawItems",
                            args: args,
                        }, {
                            onError: function (error) {
                                console.error("MetaMask transaction error:", error);
                            },
                            onSuccess: function (data) {
                                console.log("Transaction successful with data:", data);
                            },
                            onSettled: function (data, error) {
                                if (error) {
                                    console.error("Transaction error after being settled:", error);
                                }
                                else {
                                    console.log("Transaction settled with data:", data);
                                }
                            },
                        })];
                case 1:
                    transactionResponse = _a.sent();
                    console.log("Contract call successful", transactionResponse);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error during contract write:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _b = useWaitForTransactionReceipt({
        hash: hash,
    }), isConfirming = _b.isLoading, isConfirmed = _b.isSuccess;
    return {
        args: args,
        withdrawItemsContractCall: withdrawItemsContractCall,
        isSuccess: isConfirmed,
    };
};
