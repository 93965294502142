var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Button, Spinner } from "@chakra-ui/react";
export var ConnectWalletButton = function (props) {
    return (React.createElement(ConnectButton.Custom, null, function (_a) {
        var account = _a.account, chain = _a.chain, openAccountModal = _a.openAccountModal, openConnectModal = _a.openConnectModal, authenticationStatus = _a.authenticationStatus, mounted = _a.mounted, others = __rest(_a, ["account", "chain", "openAccountModal", "openConnectModal", "authenticationStatus", "mounted"]);
        var ready = mounted && authenticationStatus !== "loading";
        var connected = ready && account && chain && authenticationStatus === "authenticated";
        if (!ready) {
            return (React.createElement(Button, __assign({}, props),
                React.createElement(Spinner, null)));
        }
        if (!connected) {
            return (React.createElement(Button, __assign({ onClick: openConnectModal }, props), "Connect wallet"));
        }
        return (React.createElement(Button, __assign({ onClick: openAccountModal }, props), account.displayName));
    }));
};
