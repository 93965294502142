import { Api } from "@/api";
import { useEffect, useState } from "react";
import { useAuthToken } from "./useAuthToken";
export var useApi = function () {
    var token = useAuthToken()[0];
    var api = useState(new Api(token))[0];
    useEffect(function () {
        api.token = token;
    }, [token]);
    return { api: api, token: token };
};
