import { useEffect, useState } from "react";
export var useLocalStorage = function (key, defaultValue) {
    var _a = useState((typeof window !== "undefined" ? window.localStorage.getItem(key) : null) ||
        defaultValue), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var storageEventHandler = function (event) {
            if (event.storageArea === window.localStorage && event.key === key) {
                var storedValue = window.localStorage.getItem(key);
                setValue(storedValue);
            }
        };
        window.addEventListener("storage", storageEventHandler);
        (function () { return window.removeEventListener("storage", storageEventHandler); });
    }, []);
    var setValueAndEmitEvent = function (newValue) {
        setValue(value);
        if (newValue) {
            window.localStorage.setItem(key, newValue);
        }
        else {
            window.localStorage.removeItem(key);
        }
        window.dispatchEvent(new StorageEvent("storage", {
            storageArea: window.localStorage,
            key: key,
        }));
    };
    return [value, setValueAndEmitEvent];
};
