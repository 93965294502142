var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from "react";
export var useBridgeGearInput = function (gear) {
    var _a = useState({}), input = _a[0], setInput = _a[1];
    var select = function (id, amount, fullGearId) {
        var _a;
        console.log("select called", id, amount, fullGearId);
        var uniqueKey = "".concat(id, "-").concat(fullGearId);
        var limit = ((gear === null || gear === void 0 ? void 0 : gear.find(function (g) { return g.fullGearId === fullGearId; })) || {
            id: 0,
            amount: 0,
            fullGearId: 0,
        }).amount;
        var limitedAmount = Math.min(Math.max(0, amount), limit);
        var newInput = __assign(__assign({}, input), (_a = {}, _a[id] = __assign({ amount: limitedAmount }, (fullGearId !== undefined && { fullGearId: fullGearId })), _a));
        if (limitedAmount === 0) {
            delete newInput[id];
        }
        setInput(newInput);
    };
    return {
        input: input,
        select: select,
    };
};
