import React, { createContext, useContext, useState, useEffect } from "react";
var ChainInitiationContext = createContext({
    params: [],
    setParams: function () { },
    reset: function () { },
});
export var useChainInitiationContext = function () {
    return useContext(ChainInitiationContext);
};
export var ChainInitiationContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), params = _b[0], setParams = _b[1];
    var reset = function () {
        setParams([]);
    };
    console.log("ChainInitiationContextProvider rendered with params:", params);
    useEffect(function () {
        console.log("params in context:", params);
    }, [params]);
    return (React.createElement(ChainInitiationContext.Provider, { value: {
            params: params,
            setParams: setParams,
            reset: reset,
        } }, children));
};
