import React from "react";
import { Flex } from "@chakra-ui/react";
import { GearListing } from "./GearListing";
var disaggregateGearId = function (gearId) {
    var strGearId = gearId.toString();
    var levelStr = "";
    var i = 0;
    while (i < strGearId.length && strGearId[i] === "0") {
        i++;
    }
    while (i < strGearId.length && strGearId[i] !== "0") {
        levelStr += strGearId[i];
        i++;
    }
    var id = parseInt(strGearId.substring(i), 10);
    var level10Fix = strGearId.startsWith("10000");
    var level = levelStr === "" ? 1 : level10Fix ? 10 : parseInt(levelStr, 10);
    return { level: level, id: id };
};
export var GearList = function (_a) {
    var gear = _a.gear, gearInput = _a.gearInput, selectGear = _a.selectGear;
    return (React.createElement(Flex, { gap: 3, align: "stretch", justify: "center", flexWrap: "wrap", overflowY: "auto" }, gear === null || gear === void 0 ? void 0 : gear.map(function (g, index) {
        var _a, _b;
        return (React.createElement(GearListing, { key: "".concat(g.id, "-").concat(index), gear: g, selectGear: selectGear, selectedAmount: ((_a = gearInput[g.id]) === null || _a === void 0 ? void 0 : _a.amount) || 0, level: g.fullGearId ? disaggregateGearId(g.fullGearId).level : 1, selectedFullGearId: (_b = gearInput[g.id]) === null || _b === void 0 ? void 0 : _b.fullGearId }));
    })));
};
