var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useState, useEffect, useRef } from "react";
import { Portal, Button, Box } from "@chakra-ui/react";
import { useModalContext } from "@/contexts/ModalContext";
import styled, { keyframes } from "styled-components";
import { InitiationModal } from "./InitiationModal";
import BlackScreen from "./BlackScreen";
var bob = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { transform: translateX(-50%) translateY(0); }\n  50% { transform: translateX(-50%) translateY(-10px); }\n  100% { transform: translateX(-50%) translateY(0); }\n"], ["\n  0% { transform: translateX(-50%) translateY(0); }\n  50% { transform: translateX(-50%) translateY(-10px); }\n  100% { transform: translateX(-50%) translateY(0); }\n"])));
var breath = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% { transform: scale(1); }\n  50% { transform: scale(1.15); }\n  100% { transform: scale(1); }\n"], ["\n  0% { transform: scale(1); }\n  50% { transform: scale(1.15); }\n  100% { transform: scale(1); }\n"])));
var containerStyle = {
    position: "relative", // This is needed for absolute positioning of children
    overflow: "hidden",
    width: "100vw",
    height: "100vh",
};
var Overlay = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: url(\"/glow.png\") no-repeat center center fixed;\n  background-size: cover;\n  zindex: 1000;\n  transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);\n  transform: scale(1);\n  opacity: ", ";\n  animation: ", " 3s infinite;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: url(\"/glow.png\") no-repeat center center fixed;\n  background-size: cover;\n  zindex: 1000;\n  transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);\n  transform: scale(1);\n  opacity: ", ";\n  animation: ", " 3s infinite;\n"])), function (props) { return (props.show ? 1 : 0); }, breath);
var NoGlowOverlay = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: url(\"/noGlow.png\") no-repeat center center fixed;\n  background-size: cover;\n  zindex: 1000;\n  transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);\n  transform: scale(1);\n  opacity: ", ";\n  animation: ", " 3s infinite;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: url(\"/noGlow.png\") no-repeat center center fixed;\n  background-size: cover;\n  zindex: 1000;\n  transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);\n  transform: scale(1);\n  opacity: ", ";\n  animation: ", " 3s infinite;\n"])), function (props) { return (props.show ? 0 : 1); }, breath);
var Exclamation = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 23%;\n  left: 51.5%;\n  width: 80px;\n  height: 80px; // adjust as needed\n  background: url(\"/exclamation.png\") no-repeat center center;\n  background-size: cover;\n  animation: ", " 3s infinite;\n  transform: translateX(10px);\n"], ["\n  position: absolute;\n  top: 23%;\n  left: 51.5%;\n  width: 80px;\n  height: 80px; // adjust as needed\n  background: url(\"/exclamation.png\") no-repeat center center;\n  background-size: cover;\n  animation: ", " 3s infinite;\n  transform: translateX(10px);\n"])), bob);
var OverlayImage = function (_a) {
    var initiationData = _a.initiationData, handleConfirmClick = _a.handleConfirmClick, sendMessage = _a.sendMessage;
    var _b = useState(false), showGlowOverlay = _b[0], setShowGlowOverlay = _b[1];
    var _c = useState(false), showTextBox = _c[0], setShowTextBox = _c[1];
    var _d = useState(false), showBlackScreen = _d[0], setShowBlackScreen = _d[1];
    var _e = useState(1), blackScreenOpacity = _e[0], setBlackScreenOpacity = _e[1];
    var audio = useRef(new Audio("https://s3.amazonaws.com/assets.knightsoftheether.com/Initiation2.mp3"));
    audio.current.volume = 0.4;
    useEffect(function () {
        audio.current.loop = true;
        audio.current.addEventListener("error", handleError, false);
        return function () {
            audio.current.removeEventListener("error", handleError);
        };
    }, []);
    var handleError = function () {
        console.error("Error loading audio:", audio.current.error);
    };
    // Add error handling
    audio.current.onerror = function () {
        console.error("Error playing audio:", audio.current.error);
    };
    var _f = useModalContext(), setIsCrystalClicked = _f.setIsCrystalClicked, openInitiationModal = _f.openInitiationModal, isAuthModalOpen = _f.isAuthModalOpen, isBridgeGearModalOpen = _f.isBridgeGearModalOpen, isInitiationModalOpen = _f.isInitiationModalOpen, isTreasureAuthModalOpen = _f.isTreasureAuthModalOpen, openAuthModal = _f.openAuthModal, closeAuthModal = _f.closeAuthModal, closeBridgeGearModal = _f.closeBridgeGearModal, closeInitiationModal = _f.closeInitiationModal, closeTreasureAuthModal = _f.closeTreasureAuthModal, isCrystalClicked = _f.isCrystalClicked, showOverlay = _f.showOverlay, setShowOverlay = _f.setShowOverlay;
    var handleMouseClick = function () {
        console.log("CLICKING");
        setIsCrystalClicked(true); // Set isCrystalClicked to true
        openInitiationModal();
        // setShowOverlay(false); // Comment out or remove this line
        window.koteWeb.openInitiationModal(); // This line opens the InitiationModal
        setShowTextBox(true);
    };
    useEffect(function () {
        if (showOverlay) {
            audio.current
                .play()
                .then(function () { })
                .catch(function (error) {
                console.error("Error playing audio:", error);
            });
        }
        else {
            // Pause and reset the audio when the modal closes
            audio.current.pause();
            audio.current.currentTime = 0;
        }
    }, [showOverlay]);
    var handleBackClick = function () {
        console.log("BACK CLICKED");
        setShowGlowOverlay(false);
        setShowTextBox(false);
        setShowBlackScreen(false);
        setBlackScreenOpacity(1);
        setIsCrystalClicked(false);
        closeInitiationModal();
        setShowOverlay(false); // Add this line to close the entire overlay
        // Stop the audio when the "Back" button is clicked
        audio.current.pause();
        audio.current.currentTime = 0;
    };
    var handleMouseEnter = function () {
        setShowGlowOverlay(true);
    };
    var handleMouseLeave = function () {
        setShowGlowOverlay(false);
    };
    var backgroundImageStyle = {
        position: "relative",
        width: "100vw",
        height: "100vh",
        background: "url('/init_background.png') no-repeat center center fixed",
        backgroundSize: "cover",
    };
    var centerButtonStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50px",
        height: "50px",
        animation: "bob 1s infinite",
    };
    var hoverAreaStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-30%, -60%)",
        width: "73px", // same as button
        height: "125px", // same as button
        zIndex: 1001,
    };
    var backButtonStyle = {
        zIndex: 1002, // Ensure this is above other elements
    };
    useEffect(function () {
        if (showOverlay) {
            setTimeout(function () {
                console.log("Showing glow overlay");
                setBlackScreenOpacity(0); // Fade out the black screen
            }, 1000); // Delay showing the glow overlay and fading out the black screen
        }
        else {
            console.log("Hiding glow overlay");
            setShowGlowOverlay(false);
            setBlackScreenOpacity(1); // Fade in the black screen
        }
    }, [showOverlay]);
    return (React.createElement(Box, { style: containerStyle },
        React.createElement(Portal, null,
            initiationData.contract === null && (React.createElement(Box, { position: "fixed", top: "0", left: "0", width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0, 0, 0, 0.2)" // Semi-transparent background
                , zIndex: "5000" // Increased zIndex to ensure it's above other elements
             },
                React.createElement(Box, { backgroundColor: "grey", color: "white", p: 4, borderRadius: "md", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
                    React.createElement("div", null, "No items, please select some items and try again"),
                    React.createElement(Button, { onClick: handleBackClick }, "Back")))),
            React.createElement(BlackScreen, { opacity: blackScreenOpacity }),
            showOverlay && (React.createElement(InitiationModal, { isOpen: isInitiationModalOpen, onClose: handleBackClick, onConfirm: handleConfirmClick, showOverlay: showOverlay, setShowOverlay: setShowOverlay, initiationData: initiationData, sendMessage: sendMessage })),
            React.createElement("div", { style: backgroundImageStyle },
                React.createElement(Overlay, { show: showGlowOverlay }),
                React.createElement(NoGlowOverlay, { show: showGlowOverlay }),
                React.createElement("button", { style: centerButtonStyle }),
                React.createElement(Exclamation, null),
                React.createElement("div", { style: hoverAreaStyle, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onClick: handleMouseClick }),
                React.createElement(Button, { style: backButtonStyle, onClick: handleBackClick }, "Back"),
                " "))));
};
export default OverlayImage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
