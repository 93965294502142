var modalEventName = "modal-state-change";
export var dispatchModalEvent = function (detail) {
    var event = new CustomEvent(modalEventName, {
        detail: detail,
    });
    document.dispatchEvent(event);
};
export var addModalEventListener = function (listener) {
    document.addEventListener(modalEventName, listener);
};
export var removeModalEventListener = function (listener) {
    document.removeEventListener(modalEventName, listener);
};
