var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled from "styled-components";
var StyledBlackScreen = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: black;\n  transition: opacity 2s cubic-bezier(0.25, 0.1, 0.25, 1);\n  opacity: ", ";\n  z-index: 998;\n  display: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: black;\n  transition: opacity 2s cubic-bezier(0.25, 0.1, 0.25, 1);\n  opacity: ", ";\n  z-index: 998;\n  display: ", ";\n"])), function (props) { return props.opacity; }, function (props) { return (props.opacity === 0 ? "none" : "block"); });
var BlackScreen = function (_a) {
    var opacity = _a.opacity;
    return React.createElement(StyledBlackScreen, { opacity: opacity });
};
export default BlackScreen;
var templateObject_1;
