var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Flex, Image, Text, Grid, GridItem, } from "@chakra-ui/react";
import { useFragments } from "@/hooks/useFragments";
import fragmentData from "@/data/fragmentData.json";
import { useSwitchChain, useAccount } from "wagmi";
import { NETWORK } from "@/env";
var AWS_URL = "https://assets.knightsoftheether.com";
export var FragmentTab = function (_a) {
    var jwtToken = _a.jwtToken, onClose = _a.onClose;
    var _b = useState([]), selectedFragments = _b[0], setSelectedFragments = _b[1];
    var _c = useState(false), isContractError = _c[0], setIsContractError = _c[1];
    var _d = useState(0), refresh = _d[0], setRefresh = _d[1];
    var _e = useState(false), isClaiming = _e[0], setIsClaiming = _e[1];
    var chain = useAccount().chain;
    var switchChain = useSwitchChain().switchChain;
    var networkId = useMemo(function () {
        if (NETWORK === "mainnet") {
            return 42161; // Arbitrum
        }
        else if (NETWORK === "testnet") {
            return 421614; // Arbitrum Sepolia
        }
        else {
            throw new Error("Unsupported network: ".concat(NETWORK));
        }
    }, []);
    var handleSwitchNetwork = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Attempting to switch network...");
                    if (!switchChain) return [3 /*break*/, 2];
                    console.log("Switch network function is available. Switching network...");
                    console.log("Network ID:", networkId);
                    return [4 /*yield*/, switchChain({ chainId: networkId })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    console.error("switchNetwork function is undefined");
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _f = useFragments(jwtToken, selectedFragments, refresh), fragments = _f.fragments, isSuccess = _f.isSuccess, fragmentsError = _f.error, isError = _f.isError, handleClaimButtonClick = _f.handleClaimButtonClick, state = _f.state, dispatch = _f.dispatch;
    var handleClaim = function (fragment) {
        console.log("Claiming fragment in handle claim:", fragment);
        if (fragment.realAmount < 1) {
            return;
        }
        setSelectedFragments([fragment]);
        handleClaimButtonClick([fragment]);
    };
    var handleClaimAll = function () {
        console.log("Claiming all fragments");
        var availableFragments = fragments.filter(function (fragment) { return fragment.realAmount >= 1; });
        setSelectedFragments(availableFragments);
        handleClaimButtonClick(availableFragments);
    };
    var totalAvailableForClaim = useMemo(function () {
        return fragments.reduce(function (total, fragment) { return total + fragment.realAmount; }, 0);
    }, [fragments]);
    useEffect(function () {
        if (fragmentsError) {
            // Check if the error message indicates a user rejection
            if (fragmentsError.message === 'User rejected the request.') {
                // User rejected the transaction, reset the state
                setRefresh(function (refresh) { return refresh + 1; });
            }
            else {
                setIsContractError(true);
            }
        }
    }, [fragmentsError]);
    useEffect(function () {
        if (isSuccess) {
            console.log("Claim successful");
            onClose();
        }
    }, [isSuccess, onClose]);
    return (React.createElement(Box, { flexGrow: 1, overflowY: "auto", maxH: "calc(100vh - 200px)", m: 3 },
        React.createElement(Grid, { templateColumns: "repeat(5, 1fr)", gap: 3 }, fragments.map(function (fragment, index) {
            var gridColumn = (index % 5) + 1;
            var gridRow = Math.floor(index / 5) + 1;
            return (React.createElement(GridItem, { key: index, gridColumn: gridColumn, gridRow: gridRow },
                React.createElement(FragmentListing, { fragment: fragment, handleClaim: handleClaim, isContractError: isContractError, chain: chain, networkId: networkId })));
        })),
        React.createElement(Flex, { flexDirection: "column", alignItems: "center", marginTop: 4 },
            React.createElement(Text, { fontSize: "xl", fontWeight: "bold" }, chain && chain.id === networkId ? "Claim All" : "Switch Network"),
            React.createElement(Button, { bg: "green.500", color: "black", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: chain && chain.id === networkId
                    ? handleClaimAll
                    : handleSwitchNetwork, marginTop: 2 }, chain && chain.id === networkId ? "Claim All" : "Switch Networks"),
            React.createElement(Text, { fontSize: "md", marginTop: 2 },
                "Total Available for Claim: ",
                totalAvailableForClaim))));
};
var FragmentListing = function (_a) {
    var fragment = _a.fragment, handleClaim = _a.handleClaim, isContractError = _a.isContractError, chain = _a.chain, networkId = _a.networkId;
    var id = fragment.id, realAmount = fragment.realAmount;
    var image = "".concat(AWS_URL, "/fragments/").concat(id, ".jpg");
    var getFragmentNameById = function (id) {
        var fragment = fragmentData.find(function (fragment) { return fragment.id === id; });
        return fragment ? fragment.name : "Unknown";
    };
    return (React.createElement(Flex, { w: "170px", p: 1, flexDir: "column", align: "center", textAlign: "center", bg: "blackAlpha.400", borderWidth: 1, borderColor: "gray.800", transition: "border 0.2s", rounded: "lg" },
        React.createElement(Box, { borderColor: "black", borderWidth: 2, transition: "all 0.2s", bg: "blackAlpha.600", minH: 10, overflow: "hidden" },
            React.createElement(Image, { src: image, w: "full" })),
        React.createElement(Box, null,
            React.createElement(Text, { color: "whiteAlpha.600", fontSize: "sm" }, getFragmentNameById(id)),
            React.createElement(Text, { color: "whiteAlpha.400", fontSize: "xs" },
                "Available to Claim: ",
                realAmount)),
        React.createElement(Button, { bg: "green.500", color: "black", _hover: { bg: "green.400", transition: "background-color 0.2s" }, onClick: function () { return handleClaim(fragment); }, isDisabled: realAmount < 1 ||
                (chain === null || chain === void 0 ? void 0 : chain.id) !== networkId }, "Claim")));
};
