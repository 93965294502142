var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMemo, useEffect, useState } from "react";
import { useWriteContract, useWaitForTransactionReceipt, useReadContract, } from "wagmi";
import InitiationAbi from "../abis/initiation.json";
import { useChainInitiationContext } from "@/contexts/ChainInitiationContext";
import { useAccount } from "wagmi";
import KnightAbi from "../abis/knight.json";
import VillagerAbi from "../abis/villager.json";
import { NETWORK } from "@/env";
var contracts = {
    testnet: {
        initiation: "0x4b37190fb7F81FafEaea73b968b75116e16A8238",
        knights: "0x81829cD23D7fdFA02e4FD8Da2621351eF51A2d23",
    },
    mainnet: {
        initiation: "0xCA42b53B8332E9E576eee2eBE9804E48d200CD4A",
        knights: "0xb52d71C3DdE0cEE0faD2dCE0a9cA33fDfE06aEc9",
    },
};
var contractAddresses = contracts[NETWORK];
var initiationChecksum = contractAddresses.initiation;
var initiationContractAddress = initiationChecksum.toLowerCase();
var knightsAddress = contractAddresses.knights;
export var useChainInitiation = function () {
    var _a;
    var params = useChainInitiationContext().params;
    var _b = useState(0), key = _b[0], setKey = _b[1];
    var args = useMemo(function () { return [params]; }, [params, key]);
    var _c = useAccount(), wallet = _c.address, chain = _c.chain;
    var address = (_a = params[0]) === null || _a === void 0 ? void 0 : _a.token;
    var contractAbi = address === knightsAddress ? KnightAbi : VillagerAbi;
    var _d = useState(false), isApproved = _d[0], setIsApproved = _d[1];
    var contractReadResponse = useReadContract({
        abi: contractAbi,
        address: address,
        functionName: "isApprovedForAll",
        args: [wallet, initiationChecksum],
    });
    var isAlreadyApproved = contractReadResponse.data;
    useEffect(function () {
        if (isAlreadyApproved) {
            setIsApproved(true);
        }
    }, [isAlreadyApproved]);
    var _e = useWriteContract(), writeApproval = _e.writeContract, approvalHash = _e.data;
    var _f = useWriteContract(), writeInitiation = _f.writeContract, initiationHash = _f.data;
    var _g = useWaitForTransactionReceipt({
        hash: approvalHash,
    }), isWaitingForApprovalReceipt = _g.isLoading, isApprovalSuccess = _g.isSuccess, isApprovalPending = _g.isPending;
    var _h = useWaitForTransactionReceipt({
        hash: initiationHash,
    }), isWaitingForInitiationReceipt = _h.isLoading, isSuccess = _h.isSuccess, isLoading = _h.isLoading;
    useEffect(function () {
        if (isApprovalSuccess) {
            setIsApproved(true);
        }
    }, [isApprovalSuccess]);
    var setApprovalContractCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Calling writeContract to set approval");
                    return [4 /*yield*/, writeApproval({
                            address: address,
                            abi: contractAbi,
                            functionName: "setApprovalForAll",
                            args: [initiationChecksum, true],
                            chainId: chain === null || chain === void 0 ? void 0 : chain.id,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        console.log("Initiation isApproved:", isApproved);
    }, [isApproved]);
    // Function to initiate the contract call
    var initiateContractCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, writeInitiation({
                        address: initiationContractAddress,
                        abi: InitiationAbi,
                        functionName: "initiate",
                        args: args,
                        chainId: chain === null || chain === void 0 ? void 0 : chain.id,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // Call this function where you need to initiate the transaction
    // For example, in response to a button click
    return {
        initiateContractCall: initiateContractCall,
        setApprovalContractCall: setApprovalContractCall,
        isAlreadyApproved: isAlreadyApproved,
        isApproved: isApproved,
        isLoading: isLoading,
        isSuccess: isSuccess,
    };
};
