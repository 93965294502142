import { SiweMessage } from "siwe";
var messageStatement = "Sign into KOTE's Blightfell";
export var createSiweMessage = function (address, nonce, chainId) {
    return new SiweMessage({
        address: address,
        domain: window.location.host,
        uri: window.location.origin,
        statement: messageStatement,
        nonce: nonce,
        version: "1",
        chainId: chainId,
    });
};
