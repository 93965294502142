export var buttonTheme = {
    minWidth: "150px",
    padding: "8px 6px",
    rounded: "md",
    color: "whiteAlpha.600",
    bg: "#2a2c34",
    _hover: {
        transition: "background-color 0.2s",
        bg: "#353949",
    },
    _disabled: {
        transition: "opacity 0.2s",
        opacity: 0.3,
        cursor: "not-allowed",
    },
};
