var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { Box, Button, Flex, Text, Spinner, Center, Container, Stack, } from "@chakra-ui/react";
import { Modal } from "@/components/Modal";
import { useInitiation, contracts } from "@/hooks/useInitiation";
import { useChainInitiation } from "@/hooks/useChainInitiation";
import { useChainInitiationContext } from "@/contexts/ChainInitiationContext";
import gearData from "@/data/gearData.json";
import styled, { keyframes, css } from "styled-components";
import { NETWORK } from "@/env";
var glow = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% {\n    box-shadow: none;\n  }\n  50% {\n    box-shadow: 0 0 10px yellow, 0 0 20px yellow;\n  }\n  100% {\n    box-shadow: none;\n  }\n"], ["\n  0% {\n    box-shadow: none;\n  }\n  50% {\n    box-shadow: 0 0 10px yellow, 0 0 20px yellow;\n  }\n  100% {\n    box-shadow: none;\n  }\n"])));
var AnimatedButton = styled(Button).withConfig({
    shouldForwardProp: function (prop) { return !['isApproved', 'approvalStarted'].includes(prop); },
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return props.approvalStarted &&
        props.isApproved && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      animation: ", " 2s infinite;\n    "], ["\n      animation: ", " 2s infinite;\n    "])), glow);
});
export var InitiationModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, setShowOverlay = _a.setShowOverlay, initiationData = _a.initiationData, sendMessage = _a.sendMessage;
    var _b = useInitiation(isOpen, initiationData), initiationError = _b.initiationError, setInitiationError = _b.setInitiationError, isCharacterInitiated = _b.isCharacterInitiated, setIsCharacterInitiated = _b.setIsCharacterInitiated, setShouldChangeEquipment = _b.setShouldChangeEquipment, isChangeEquipmentSuccess = _b.isChangeEquipmentSuccess, isLoadingCharacter = _b.isLoadingCharacter, generatedCharacterImage = _b.generatedCharacterImage, initiateAndFetch = _b.initiateAndFetch, isCharacterInProgress = _b.isCharacterInProgress;
    var _c = useChainInitiationContext(), reset = _c.reset, params = _c.params;
    var knownContracts = contracts[NETWORK];
    useEffect(function () {
        if (params.length > 0 && params[0].token) {
            var contractAddress_1 = params[0].token.toLowerCase();
            var isValidContract = Object.values(knownContracts).some(function (address) {
                console.log("Comparing:", address.toLowerCase(), "with", contractAddress_1);
                return address.toLowerCase() === contractAddress_1;
            });
            if (!isValidContract) {
                console.log("Character is unable to initiate due to unknown contract address.", contractAddress_1);
                setInitiationError("Cannot initiate this character. Please select a blessed villager or knight.");
                return;
            }
        }
    }, [params, knownContracts]);
    var _d = useChainInitiation(), initiateContractCall = _d.initiateContractCall, setApprovalContractCall = _d.setApprovalContractCall, isAlreadyApproved = _d.isAlreadyApproved, isApproved = _d.isApproved, isLoading = _d.isLoading, isSuccess = _d.isSuccess;
    var _e = useState(false), approvalStarted = _e[0], setApprovalStarted = _e[1];
    var _f = useState("initiate"), tab = _f[0], setTab = _f[1];
    var _g = useState(false), isImageConfirmed = _g[0], setIsImageConfirmed = _g[1];
    var _h = useState(null), showConfirmationScreen = _h[0], setShowConfirmationScreen = _h[1];
    var _j = useState("idle"), transactionStatus = _j[0], setTransactionStatus = _j[1];
    useEffect(function () { }, [isCharacterInitiated]);
    var handleConfirmClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            try {
                setTransactionStatus("pending");
                if (!isApproved) {
                    console.log("Calling setApprovalForAll...");
                    setApprovalContractCall();
                    setApprovalStarted(true);
                }
                else {
                    setApprovalStarted(false);
                    initiateContractCall();
                    reset();
                }
            }
            catch (error) {
                console.error("Failed to initiate transaction:", error);
                setTransactionStatus("error");
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, closeOnOverlayClick: false, contentProps: {
            maxWidth: "960px",
            padding: 0,
            zIndex: 2000,
        } },
        React.createElement(React.Fragment, null,
            initiationError ? (React.createElement(Container, { m: [2, 3] },
                React.createElement(Center, null,
                    React.createElement(Box, { p: 5, color: "white", bg: "red.500", borderRadius: "md", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" },
                        React.createElement(Text, { fontSize: "2xl", fontWeight: "bold", mt: 3 }, "An error occurred during initiation!"),
                        React.createElement(Text, { mt: 2 }, initiationError))))) : isLoading ? (React.createElement(Container, { m: [2, 3] },
                React.createElement(Center, null,
                    React.createElement(Box, { p: 5, bg: "gray.200", borderRadius: "md", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
                        React.createElement(Spinner, { size: "xl", speed: "0.65s", color: "blue.500" }),
                        React.createElement(Text, { fontSize: "xl", fontWeight: "bold", mt: 3, color: "blue.500" }, "Processing your transaction..."))))) : isSuccess || isChangeEquipmentSuccess ? (React.createElement(Container, { m: [2, 3] },
                React.createElement(Center, null,
                    React.createElement(Box, { p: 5, color: "white", bg: "green.500", borderRadius: "md", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" },
                        React.createElement(Text, { fontSize: "2xl", fontWeight: "bold", mt: 3 }, isCharacterInitiated
                            ? "The Order approves of these changes. Go forth to Blightfell and bring us glory."
                            : "The Order accepts you as one of their own!"),
                        React.createElement(Text, { mt: 2 }, isCharacterInitiated
                            ? "Gear successfully changed."
                            : "You are Initiated."))))) : (React.createElement(React.Fragment, null,
                React.createElement(Flex, { align: "center", justify: "center" // Change from "space-between" to "center"
                    , flexShrink: 0, padding: "20px 30px", borderBottomWidth: 1, borderColor: "blackAlpha.600", gap: 2, wrap: "wrap" },
                    React.createElement(Stack, { spacing: 3, align: "center", justify: "center" },
                        React.createElement(Text, { fontSize: "3xl", color: "purple.500" }, !isImageConfirmed
                            ? "Are you sure you wish to join our ranks?"
                            : "Step forward and kneel before the crystal."),
                        React.createElement(Text, { fontSize: "2xl", color: "purple.500" }, isImageConfirmed && "Your time has come to join the order."))),
                tab === "initiate" && (React.createElement("div", null, isLoadingCharacter ? (React.createElement(Container, { m: [2, 3] },
                    React.createElement(Center, null,
                        React.createElement(Box, { p: 5, bg: "gray.200", borderRadius: "md", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" },
                            React.createElement(Spinner, { size: "xl", speed: "0.65s", color: "blue.500" }),
                            React.createElement(Text, { fontSize: "xl", fontWeight: "bold", mt: 3, color: "blue.500" }, "Loading character..."))))) : (generatedCharacterImage && (React.createElement("div", { style: { display: "flex", justifyContent: "center" } },
                    React.createElement("img", { src: generatedCharacterImage, alt: "Generated character", style: { width: "400px" } })))))))),
            showConfirmationScreen == "confirm" && (React.createElement(Container, { m: [2, 3] },
                React.createElement(Center, null,
                    React.createElement(Box, { p: 5, color: "white", bg: "green.500", borderRadius: "md", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" },
                        React.createElement(Text, { fontSize: "2xl", fontWeight: "bold", mt: 3 }, "Are you sure? This will burn the gear pieces you are replacing."),
                        React.createElement(Text, { mt: 2 }, "Click confirm to proceed."))))),
            generatedCharacterImage &&
                !isLoadingCharacter &&
                !isImageConfirmed && (React.createElement(Center, null,
                React.createElement(Box, null,
                    React.createElement(Flex, { direction: "column", alignItems: "center", justifyContent: "center" },
                        React.createElement(Text, null, isCharacterInitiated
                            ? "Confirm these items"
                            : isCharacterInProgress
                                ? "This character has already been initiated, the final step is to mint it on chain."
                                : "Clicking confirm locks these items in and you will need to re-initiate to change them."),
                        !isCharacterInProgress &&
                            initiationData.gearIds.length > 0 && (React.createElement(Flex, { direction: "column", alignItems: "center", justifyContent: "center" },
                            React.createElement(Text, null, "Gear:"),
                            React.createElement(Flex, { justifyContent: "center", flexWrap: "wrap" }, initiationData.gearIds.map(function (id) {
                                var gear = gearData.find(function (item) { return item.edition === id; });
                                return gear ? (React.createElement("img", { src: gear.image.replace("ipfs://", "https://ipfs.io/ipfs/"), alt: gear.name, style: { width: "40px", margin: "5px" } })) : null;
                            })))),
                        React.createElement(Button, { m: 4, onClick: function () {
                                setIsImageConfirmed(true);
                                if (!isCharacterInitiated) {
                                    initiateAndFetch();
                                }
                                else {
                                    setShouldChangeEquipment(true);
                                    setShowConfirmationScreen("success");
                                }
                            } }, "Confirm"))))),
            (isImageConfirmed || initiationError) && !isLoading && (React.createElement(Footer, null,
                React.createElement(Flex, null,
                    React.createElement(AnimatedButton, { approvalStarted: approvalStarted, isApproved: isApproved, bg: initiationError ? "red.500" : "green.500", color: "black", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                if (isSuccess || initiationError) {
                                    onClose();
                                    setApprovalStarted(false);
                                    setIsCharacterInitiated(false);
                                    setShowOverlay(false);
                                    setInitiationError(null);
                                    if (isSuccess) {
                                        // Send Unity message when transaction is successful
                                        console.log("Sending Unity initiated-success event...");
                                        sendMessage("WebBridge", "OnWebMessage", "initiated-success");
                                        setApprovalStarted(false);
                                    }
                                }
                                else if (showConfirmationScreen === "success") {
                                    onClose();
                                    setApprovalStarted(false);
                                    console.log("Sending Unity initiated-success event...");
                                    sendMessage("WebBridge", "OnWebMessage", "initiated-success");
                                }
                                else if (showConfirmationScreen === "confirm") {
                                    console.log("changing equipment...");
                                    setShowConfirmationScreen("success");
                                }
                                else if (isCharacterInitiated) {
                                    setShowConfirmationScreen("confirm");
                                }
                                else {
                                    handleConfirmClick();
                                }
                                return [2 /*return*/];
                            });
                        }); }, _hover: {
                            bg: initiationError ? "red.400" : "green.400",
                            transition: "background-color 0.2s",
                        }, disabled: !initiateContractCall }, initiationError
                        ? "Back"
                        : isSuccess
                            ? "Continue"
                            : showConfirmationScreen === "success"
                                ? "Continue"
                                : showConfirmationScreen === "confirm"
                                    ? "Confirm"
                                    : isCharacterInitiated
                                        ? "Confirm"
                                        : isApproved
                                            ? "Confirm"
                                            : "Approve")))))));
};
var Footer = function (_a) {
    var children = _a.children;
    return (React.createElement(Flex, { align: "center", justify: "space-between", flexShrink: 0, padding: "20px 30px", borderTopWidth: 1, borderColor: "blackAlpha.600" }, children));
};
var templateObject_1, templateObject_2, templateObject_3;
