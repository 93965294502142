var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Modal as ChakraModal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from "@chakra-ui/react";
export var Modal = function (_a) {
    var header = _a.header, children = _a.children, footer = _a.footer, _b = _a.contentProps, contentProps = _b === void 0 ? {} : _b, modalProps = __rest(_a, ["header", "children", "footer", "contentProps"]);
    return (React.createElement(ChakraModal, __assign({ isCentered: true, motionPreset: "scale", size: "sm" }, modalProps),
        React.createElement(ModalOverlay, null),
        React.createElement(ModalContent, __assign({}, contentProps),
            header && React.createElement(ModalHeader, null, header),
            children && React.createElement(ModalBody, null, children),
            footer && React.createElement(ModalFooter, null, footer))));
};
