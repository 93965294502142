var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useMemo, useState } from "react";
import { useWriteContract, useWaitForTransactionReceipt, } from "wagmi";
import { contracts } from "@/walletConfig";
import { disaggregateGearId } from "@/gear";
import BridgeAbi from "../abis/bridge.json";
export var useDeposit = function (depositItems) {
    var _a = useState(false), isSuccess = _a[0], setIsSuccess = _a[1];
    var args = useMemo(function () {
        var ids = [];
        var amounts = [];
        depositItems.forEach(function (_a) {
            var id = _a.id, amount = _a.amount, fullGearId = _a.fullGearId;
            console.log("deposit item", id, amount, fullGearId);
            if (fullGearId) {
                var level = disaggregateGearId(fullGearId).level;
                if (level > 1) {
                    ids.push(fullGearId);
                    console.log("Full gear id pushed", fullGearId);
                }
                else {
                    ids.push(id);
                    console.log("base id pushed", id);
                }
            }
            else {
                ids.push(id);
            }
            amounts.push(amount);
        });
        return [ids, amounts];
    }, [depositItems]);
    var _b = useState(null), transactionHash = _b[0], setTransactionHash = _b[1];
    var _c = useWriteContract(), writeContract = _c.writeContract, hash = _c.data, isPending = _c.isPending;
    // Function to initiate the deposit items contract call
    var depositItemsContractCall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var transactionResponse, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    console.log("Calling contract with args:", args);
                    return [4 /*yield*/, writeContract({
                            address: contracts.bridge,
                            abi: BridgeAbi,
                            functionName: "depositItems",
                            args: args,
                        }, {
                            onError: function (error) {
                                console.error("MetaMask transaction error:", error);
                            },
                            onSuccess: function (data) {
                                console.log("Transaction successful with data:", data);
                            },
                            onSettled: function (data, error) {
                                if (error) {
                                    console.error("Transaction error after being settled:", error);
                                }
                                else {
                                    console.log("Transaction settled with data:", data);
                                }
                            },
                        })];
                case 1:
                    transactionResponse = _a.sent();
                    console.log("Contract call successful", transactionResponse);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error during contract write:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var _d = useWaitForTransactionReceipt({
        hash: hash,
    }), isConfirming = _d.isLoading, isConfirmed = _d.isSuccess;
    return {
        depositItemsContractCall: depositItemsContractCall,
        transactionHash: transactionHash,
        isSuccess: isConfirmed,
    };
};
