import { useProfile } from "./useProfile";
import { useEffect, useState } from "react";
export var useAuthStatus = function () {
    var _a = useProfile(), isAuthenticated = _a.isAuthenticated, isFetching = _a.isFetching, isSuccess = _a.isSuccess;
    var _b = useState("loading"), authStatus = _b[0], setAuthStatus = _b[1];
    useEffect(function () {
        if (isFetching) {
            setAuthStatus("loading");
        }
        else if (!isAuthenticated) {
            setAuthStatus("unauthenticated");
        }
        else if (isSuccess) {
            setAuthStatus("authenticated");
        }
        else {
            setAuthStatus("unauthenticated");
        }
    }, [isFetching, isAuthenticated, isSuccess]);
    return authStatus;
};
