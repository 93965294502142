import React, { useMemo } from "react";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { getGearDefinition } from "../../gear";
export var GearListing = function (_a) {
    var gear = _a.gear, selectGear = _a.selectGear, selectedAmount = _a.selectedAmount, level = _a.level, selectedFullGearId = _a.selectedFullGearId;
    var _b = useMemo(function () { return getGearDefinition(gear.id); }, [gear]), name = _b.name, image = _b.image, rarity = _b.rarity;
    var highlight = selectedAmount > 0 && selectedFullGearId === gear.fullGearId;
    return (React.createElement(Flex, { w: "170px", p: 3, flexDir: "column", align: "center", textAlign: "center", gap: 3, bg: "blackAlpha.400", borderWidth: 1, borderColor: highlight ? "green.700" : "gray.800", transition: "border 0.2s", rounded: "lg" },
        React.createElement(Box, { borderColor: "black", borderWidth: 2, transition: "all 0.2s", bg: "blackAlpha.600", minH: 10, overflow: "hidden", filter: highlight ? undefined : "grayscale(60%)" },
            React.createElement(Image, { src: image, w: "full" })),
        React.createElement(Box, null,
            React.createElement(Text, { color: highlight ? "green.600" : "whiteAlpha.600", fontSize: "sm" },
                name,
                " (Level: ",
                level,
                ")"),
            React.createElement(Text, { color: "whiteAlpha.400", fontSize: "xs" }, rarity)),
        React.createElement(Flex, { w: "full", align: "stretch", justify: "end", mt: "auto", wrap: "wrap", gap: 1 },
            [0, 10, 20].map(function (amount) {
                return gear.amount > amount && (React.createElement(SelectAmountButton, { key: amount, onSelect: function () { return selectGear(gear.id, amount, gear.fullGearId); }, isDisabled: amount === selectedAmount, amount: amount }, amount));
            }),
            React.createElement(SelectAmountButton, { onSelect: function () { return selectGear(gear.id, gear.amount, gear.fullGearId); }, isDisabled: gear.amount === selectedAmount, amount: gear.amount }, "Max")),
        React.createElement(Flex, { w: "full", align: "center", justify: "space-between", gap: 3 },
            React.createElement(Button, { minW: "30px", padding: "4px", onClick: function () { return selectGear(gear.id, selectedAmount - 1, gear.fullGearId); }, isDisabled: selectedAmount === 0 }, "-"),
            React.createElement(Flex, { flexDir: "column", align: "center" },
                React.createElement(Text, { fontWeight: "bold", color: highlight ? "green.500" : "gray.700" }, gear.fullGearId === selectedFullGearId ? selectedAmount : 0),
                React.createElement(Text, { fontSize: "xs" }, gear.amount)),
            React.createElement(Button, { minW: "30px", padding: "4px", onClick: function () { return selectGear(gear.id, selectedAmount + 1, gear.fullGearId); }, isDisabled: selectedAmount === gear.amount }, "+"))));
};
var SelectAmountButton = function (_a) {
    var onSelect = _a.onSelect, isDisabled = _a.isDisabled, amount = _a.amount, children = _a.children;
    return (React.createElement(Button, { padding: "2px 4px", minW: "30px", onClick: onSelect, isDisabled: isDisabled, fontSize: "xs", _disabled: amount !== 0
            ? {
                color: "black",
                bg: "green.500",
                opacity: 1,
            }
            : undefined }, children));
};
