import { modalAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
var _a = createMultiStyleConfigHelpers(modalAnatomy.keys), definePartsStyle = _a.definePartsStyle, defineMultiStyleConfig = _a.defineMultiStyleConfig;
var baseStyle = definePartsStyle({
    overlay: {
        bg: "rgba(0, 0, 0, 0.8)",
        backdropFilter: "blur(5px)",
    },
    dialogContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        paddingX: "20px",
    },
    dialog: {
        rounded: "xl",
        bg: "#1a1c1e",
        borderWidth: "1px",
        borderColor: "gray.700",
        padding: "20px",
        maxWidth: "800px",
        color: "gray.600",
        boxShadow: "2px 2px 30px rgba(0, 0, 0, 0.3)",
    },
});
export var modalTheme = defineMultiStyleConfig({ baseStyle: baseStyle });
