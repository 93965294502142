import React from "react";
import ReactDOM from "react-dom/client";
import { AppContext } from "./AppContext";
import App from "./App";
import './index.css';
import "./global/KoteWeb";
import "@rainbow-me/rainbowkit/styles.css";
var WrappedApp = function () { return (React.createElement(AppContext, null,
    React.createElement(App, null))); };
document.addEventListener("DOMContentLoaded", function () {
    var element = document.createElement("div");
    document.body.appendChild(element);
    var root = ReactDOM.createRoot(element);
    root.render(React.createElement(WrappedApp, null));
});
