var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useEffect } from "react";
import axios from "axios";
import { useOwnershipSubgraph } from "./useOwnershipSubgraph";
import { useChainInitiationContext } from "@/contexts/ChainInitiationContext";
import { useModalContext } from "@/contexts/ModalContext";
import { useBridgeSocket } from "@/contexts/BridgeSocket";
import { useAuthToken } from "@/hooks/useAuthToken";
import { BRIDGE_SERVER_URL, NETWORK } from "@/env";
export var contracts = {
    testnet: {
        initiatedVillager: "0x8ad34045e58c3035450cbd281278d243105b0c47",
        initiatedKnight: "0x9273607C6CC52003B40E25ce1A64feED71e84DDE",
        knights: "0x81829cD23D7fdFA02e4FD8Da2621351eF51A2d23",
        villagers: "0xF4b883107140994f79f09E28E16B1b85f1944a8C",
    },
    mainnet: {
        initiatedVillager: "0x941dA804AA45B3272A8518D9226883E1572e525e",
        initiatedKnight: "0xfD2b634Dc78ECe6f240540B0556725fc5eC4bD7d",
        knights: "0xb52d71C3DdE0cEE0faD2dCE0a9cA33fDfE06aEc9",
        villagers: "0x39601F84eBD4bdca3973E9140c52803855785111",
    },
};
var contractAddresses = contracts[NETWORK];
var initiatedVillager = contractAddresses.initiatedVillager;
var initiatedKnight = contractAddresses.initiatedKnight;
var knights = contractAddresses.knights;
var villagers = contractAddresses.villagers;
export function aggregateGearId(level, id) {
    if (level < 1 || level > 12) {
        throw new Error("Level must be between 1 and 12");
    }
    var levelStr = level.toString().padStart(2, '0');
    var newGearId = parseInt(levelStr + '000' + id.toString(), 10);
    return newGearId;
}
export var useInitiation = function (isOpen, initiationData) {
    var token = useAuthToken()[0];
    var _a = useChainInitiationContext(), params = _a.params, setParams = _a.setParams;
    var _b = useBridgeSocket(), login = _b.login, socket = _b.socket;
    var _c = useState(null), bridgeToken = _c[0], setBridgeToken = _c[1];
    var _d = useState(null), initiationError = _d[0], setInitiationError = _d[1];
    var _e = useState(false), shouldChangeEquipment = _e[0], setShouldChangeEquipment = _e[1];
    var _f = useState(false), isChangeEquipmentSuccess = _f[0], setIsChangeEquipmentSuccess = _f[1];
    var _g = useState(false), isCharacterInitiated = _g[0], setIsCharacterInitiated = _g[1];
    var _h = useState(false), isCharacterInProgress = _h[0], setIsCharacterInProgress = _h[1];
    var _j = useState(null), generatedCharacterImage = _j[0], setGeneratedCharacterImage = _j[1];
    var _k = useState(false), isLoadingCharacter = _k[0], setIsLoadingCharacter = _k[1];
    var _l = useState([]), inProgress = _l[0], setInProgress = _l[1];
    var _m = useState(null), initiatedCharacter = _m[0], setInitiatedCharacter = _m[1];
    var _o = useModalContext(), setIsCrystalClicked = _o.setIsCrystalClicked, isCrystalClicked = _o.isCrystalClicked;
    useEffect(function () {
        console.log("isChangeEquipmentSuccess", isChangeEquipmentSuccess);
    }, [isChangeEquipmentSuccess]);
    useEffect(function () {
        if (!socket || bridgeToken)
            return;
        var handleLoginResponse = function (response) {
            console.log("Handling login response", response);
            if (response.success) {
                setBridgeToken(response.token);
            }
        };
        socket.on("login", handleLoginResponse);
        if (token && !bridgeToken) {
            login(token);
        }
        return function () {
            socket.off("login", handleLoginResponse);
        };
    }, [socket, bridgeToken]);
    var isSubgraphLoading = useOwnershipSubgraph(initiationData.wallet).isLoading;
    useEffect(function () {
        if (initiationData && initiationData.contract) {
            console.log("Initiation data:", initiationData);
            var newTokenId = initiationData.tokenId;
            setParams([__assign(__assign({}, params[0]), { tokenId: newTokenId })]);
            if (initiationData.contract.toLowerCase() ===
                initiatedVillager.toLowerCase() ||
                initiationData.contract.toLowerCase() === initiatedKnight.toLowerCase()) {
                console.log("TEST-setting to true because of contract", initiationData.contract.toLowerCase());
                setIsCharacterInitiated(true);
            }
            else {
                setIsCharacterInitiated(false);
            }
        }
    }, [initiationData]);
    var initiateAndFetch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1, response, inProgressData, error_2, responseOwnership, signaturesData, newParams, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("initiateAndFetch()");
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    console.log("initiating gear chain starting");
                    return [4 /*yield*/, initiateGearChain()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    if (error_1.message.startsWith("Missing item types")) {
                        console.log("Caught missing item types error");
                        return [2 /*return*/];
                    }
                    return [3 /*break*/, 4];
                case 4:
                    _a.trys.push([4, 6, , 7]);
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/inprogress"), {
                            ids: [initiationData.tokenId],
                            contract: initiationData.contract,
                        }, { timeout: 5000 } // 5 seconds timeout
                        )];
                case 5:
                    response = _a.sent();
                    console.log("Response from inprogress:", response);
                    inProgressData = [];
                    if (response.data.success) {
                        inProgressData = response.data.inProgress;
                        setInProgress(inProgressData);
                        if (inProgressData.length === 0) {
                            console.error("Error: Returned inProgressData array is empty");
                        }
                    }
                    return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    if (error_2.code === "ECONNABORTED") {
                        console.error("Timeout error:", error_2.message);
                        setInitiationError("Timeout error");
                    }
                    return [3 /*break*/, 7];
                case 7:
                    _a.trys.push([7, 9, , 10]);
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/getmints"), {
                            wallet: initiationData.wallet,
                            tokenIds: [initiationData.tokenId],
                            contract: [initiationData.contract],
                        }, { timeout: 5000 } // 5 seconds timeout
                        )];
                case 8:
                    responseOwnership = _a.sent();
                    // handle response
                    console.log("Response from getmints:", responseOwnership);
                    if (responseOwnership.data.success) {
                        signaturesData = responseOwnership.data.signature;
                        if (signaturesData.length === 0) {
                            setInitiationError("Error: Returned signaturesData array is empty");
                            console.error("Error: Returned signaturesData array is empty");
                            return [2 /*return*/];
                        }
                        newParams = signaturesData.map(function (sig) { return ({
                            token: sig.contract,
                            tokenId: sig.tokenId,
                            signature: sig.signature,
                            crestId: sig.crestId,
                            sigilId: sig.sigilId,
                        }); });
                        setParams(newParams);
                    }
                    return [3 /*break*/, 10];
                case 9:
                    error_3 = _a.sent();
                    if (error_3.code === "ECONNABORTED") {
                        console.error("Timeout error:", error_3.message);
                        setInitiationError("Timeout error");
                    }
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (isOpen && initiationData && isCrystalClicked && bridgeToken) {
            if (isCharacterInitiated) {
                console.log("isCharacterInitiated", isCharacterInitiated);
                // Set the flag to trigger equipment change
            }
            else {
                // Continue with the current flow
            }
            setIsLoadingCharacter(true);
        }
    }, [
        isCrystalClicked,
        isOpen,
        initiationData,
        bridgeToken,
        isCharacterInitiated,
    ]);
    var initiateGearChain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var aggregatedGearIds, payload, response, errorMessage, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!bridgeToken) {
                        console.error("initiating gear chain failed");
                        return [2 /*return*/];
                    }
                    aggregatedGearIds = initiationData.gearIds.map(function (gear) { return aggregateGearId(gear.level, gear.gearId); });
                    console.log("aggregatedGearIds", aggregatedGearIds);
                    payload = {
                        tokenId: initiationData.tokenId,
                        contract: initiationData.contract,
                        gearIds: aggregatedGearIds, // Use aggregated gear IDs
                        token: bridgeToken,
                    };
                    console.log("Payload for initiation:", payload);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/initiation"), payload, { timeout: 5000 } // 5 seconds timeout
                        )];
                case 2:
                    response = _a.sent();
                    console.log("Response from initiation:", response);
                    if (!response.data.success &&
                        response.data.message !== "Token already initiated") {
                        console.error("Initiation error:", response.data.message);
                        setInitiationError(response.data.message);
                    }
                    if (!response.data || !response.data.success) {
                        errorMessage = response.data && response.data.message
                            ? response.data.message
                            : "Initiation failed";
                        throw new Error(errorMessage);
                    }
                    if (!response.data.success &&
                        response.data.message.startsWith("Missing item types")) {
                        console.error("Initiation error:", response.data.message);
                        setInitiationError(response.data.message);
                        throw new Error("MissingItemTypesError");
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    if (error_4.code === "ECONNABORTED") {
                        console.error("Timeout error:", error_4.message);
                        setInitiationError("Timeout error");
                    }
                    else {
                        console.error("Error during initiation:", error_4);
                        throw error_4;
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        console.log("params:", params);
    }, [params]);
    var fetchInitiatedCharacter = function (tokenId) { return __awaiter(void 0, void 0, void 0, function () {
        var characterType, response, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log("Fetching character for tokenId ".concat(tokenId));
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    characterType = "";
                    if (initiationData.contract.toLowerCase() ===
                        initiatedKnight.toLowerCase() ||
                        initiationData.contract.toLowerCase() === knights.toLowerCase()) {
                        characterType = "initiated-knight";
                    }
                    else if (initiationData.contract.toLowerCase() ===
                        initiatedVillager.toLowerCase() ||
                        initiationData.contract.toLowerCase() === villagers.toLowerCase()) {
                        characterType = "initiated-villager";
                    }
                    console.log("attempting for char data, ".concat(BRIDGE_SERVER_URL, "/metadata/512/").concat(characterType, "/").concat(tokenId));
                    return [4 /*yield*/, axios.get("".concat(BRIDGE_SERVER_URL, "/metadata/512/").concat(characterType, "/").concat(tokenId))];
                case 3:
                    response = _a.sent();
                    console.log("Response from initiated character:", response);
                    setInitiatedCharacter(response.data);
                    setGeneratedCharacterImage(response.data.image);
                    return [3 /*break*/, 5];
                case 4:
                    error_5 = _a.sent();
                    setInitiationError("Error fetching initiated character");
                    console.error("Error fetching initiated character:", error_5);
                    return [3 /*break*/, 5];
                case 5:
                    setIsLoadingCharacter(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var changeEquipment = function (tokenId, contract, gearIds, token) { return __awaiter(void 0, void 0, void 0, function () {
        var aggregatedGearIds, response, errorMessage, error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    aggregatedGearIds = initiationData.gearIds.map(function (gear) { return aggregateGearId(gear.level || 1, gear.gearId); });
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/changeequipment"), {
                            tokenId: tokenId,
                            contract: contract,
                            gearIds: aggregatedGearIds,
                            token: token,
                        }, { timeout: 5000 } // 5 seconds timeout
                        )];
                case 1:
                    response = _a.sent();
                    console.log("Response from changeequipment:", response);
                    if (!response.data.success) {
                        console.error("Change equipment error:", response.data.message);
                        setInitiationError(response.data.message);
                    }
                    if (!response.data || !response.data.success) {
                        errorMessage = response.data && response.data.message
                            ? response.data.message
                            : "Change equipment failed";
                        throw new Error(errorMessage);
                    }
                    // Set isChangeEquipmentSuccess to true when the request succeeds
                    setIsChangeEquipmentSuccess(true);
                    return [2 /*return*/, response.data]; // Return the response data
                case 2:
                    error_6 = _a.sent();
                    if (error_6.code === "ECONNABORTED") {
                        console.error("Timeout error:", error_6.message);
                        setInitiationError("Timeout error");
                    }
                    else {
                        console.error("Error during change equipment:", error_6);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (shouldChangeEquipment && isCharacterInitiated && bridgeToken) {
            var contractToPass = initiationData.contract.toLowerCase() === initiatedKnight.toLowerCase()
                ? knights
                : villagers;
            var gearIds = initiationData.gearIds.map(function (gear) { return gear.gearId; });
            changeEquipment(initiationData.tokenId, contractToPass, gearIds, bridgeToken).then(function (response) {
                // Reset the flag
                setShouldChangeEquipment(false);
            });
        }
    }, [shouldChangeEquipment, isCharacterInitiated, bridgeToken]);
    useEffect(function () {
        if (isOpen && initiationData && !initiationError) {
            fetchGeneratedCharacter();
        }
    }, [isOpen, initiationData]);
    var fetchGeneratedCharacter = function () { return __awaiter(void 0, void 0, void 0, function () {
        var responseInProgress, isInProgress, tokenType, gearIds, payload, response, error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/inprogress"), {
                            ids: [initiationData.tokenId],
                            contract: initiationData.contract,
                        }, { timeout: 5000 } // 5 seconds timeout
                        )];
                case 1:
                    responseInProgress = _a.sent();
                    isInProgress = responseInProgress.data.success &&
                        responseInProgress.data.inProgress.length > 0;
                    if (isInProgress) {
                        // If the character is in progress, fetch the initiated character
                        console.log("Character is in progress, fetching initiated character.");
                        fetchInitiatedCharacter(initiationData.tokenId);
                        setIsCharacterInProgress(true);
                        return [2 /*return*/];
                    }
                    console.log("Character is not in progress, generating new character.");
                    tokenType = "";
                    if (initiationData.contract.toLowerCase() === initiatedKnight.toLowerCase()) {
                        tokenType = "initiated-knight";
                    }
                    else if (initiationData.contract.toLowerCase() === knights.toLowerCase()) {
                        tokenType = "knight";
                    }
                    else if (initiationData.contract.toLowerCase() ===
                        initiatedVillager.toLowerCase()) {
                        tokenType = "initiated-villager";
                    }
                    else if (initiationData.contract.toLowerCase() === villagers.toLowerCase()) {
                        tokenType = "villager";
                    }
                    gearIds = initiationData.gearIds.map(function (gear) { return gear.gearId; });
                    payload = {
                        tokenType: tokenType,
                        token: initiationData.tokenId,
                        gearIds: gearIds, // Corrected mapping
                    };
                    console.log("Payload sent to gencharacter:", payload); // Added log for payload
                    return [4 /*yield*/, axios.post("".concat(BRIDGE_SERVER_URL, "/gencharacter"), payload, { timeout: 5000 } // 5 seconds timeout
                        )];
                case 2:
                    response = _a.sent();
                    console.log("Respnse from /gencharacter:", response);
                    if (response.data.success === false) {
                        console.error("Error from gencharacter:", response.data.error);
                        setInitiationError(response.data.error);
                        return [2 /*return*/];
                    }
                    setGeneratedCharacterImage(response.data.image);
                    console.log("Generated character image set:", response.data.image);
                    setIsLoadingCharacter(false);
                    return [3 /*break*/, 4];
                case 3:
                    error_7 = _a.sent();
                    console.error("Error fetching generated character:", error_7);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return {
        inProgress: inProgress,
        initiatedCharacter: initiatedCharacter,
        isCrystalClicked: isCrystalClicked,
        setIsCrystalClicked: setIsCrystalClicked,
        initiationError: initiationError,
        setInitiationError: setInitiationError,
        isCharacterInitiated: isCharacterInitiated,
        setIsCharacterInitiated: setIsCharacterInitiated,
        setShouldChangeEquipment: setShouldChangeEquipment,
        isChangeEquipmentSuccess: isChangeEquipmentSuccess,
        isLoadingCharacter: isLoadingCharacter,
        generatedCharacterImage: generatedCharacterImage,
        initiateAndFetch: initiateAndFetch,
        setIsCharacterInProgress: setIsCharacterInProgress,
        isCharacterInProgress: isCharacterInProgress,
    };
};
