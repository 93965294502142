import { authTokenLocalStorageKey, verifyAuthToken } from "@/auth/token";
import { useLocalStorage } from "./useLocalStorage";
export var useAuthToken = function () {
    var _a = useLocalStorage(authTokenLocalStorageKey, null), token = _a[0], setToken = _a[1];
    if (token && !verifyAuthToken(token)) {
        console.warn("Authentication JWT has expired");
        setToken(null);
        return [null, setToken];
    }
    return [token, setToken];
};
