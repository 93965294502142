var authTokenChangeEventName = "auth-token-change";
export var dispatchAuthTokenChangeEvent = function (detail) {
    var event = new CustomEvent(authTokenChangeEventName, {
        detail: detail,
    });
    document.dispatchEvent(event);
};
export var addAuthTokenChangeEventListener = function (listener) {
    document.addEventListener(authTokenChangeEventName, listener);
};
export var removeAuthTokenChangeEventListener = function (listener) {
    document.removeEventListener(authTokenChangeEventName, listener);
};
