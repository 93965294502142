var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useReducer, useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useWriteContract, useWaitForTransactionReceipt, } from "wagmi";
import { useAccount } from "wagmi";
import { contracts } from "@/walletConfig";
import FragmentsAbi from "../abis/fragments.json";
import fragmentData from "@/data/fragmentData.json";
import { NETWORK } from "../env";
function reducer(state, action) {
    switch (action.type) {
        case "SET_WRITE_ASYNC_AVAILABLE":
            return __assign(__assign({}, state), { isWriteAsyncAvailable: action.payload });
        case "SET_CLAIM_BUTTON_CLICKED":
            return __assign(__assign({}, state), { isClaimButtonClicked: action.payload });
        default:
            return state;
    }
}
//===
export var useFragments = function (jwtToken, selectedFragments, refresh) {
    var _a = useAccount(), wallet = _a.address, isConnecting = _a.isConnecting, isDisconnected = _a.isDisconnected;
    var _b = useState([]), fragments = _b[0], setFragments = _b[1];
    var _c = useState(false), isMounted = _c[0], setIsMounted = _c[1];
    var _d = useState(true), isWriteLoading = _d[0], setIsWriteLoading = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useReducer(reducer, {
        isWriteAsyncAvailable: false,
        isClaimButtonClicked: false,
    }), state = _f[0], dispatch = _f[1];
    var defaultFragments = Array.from({ length: 15 }, function (_, i) { return ({
        id: i + 1,
        amount: 0,
        realAmount: 0,
        signature: "",
    }); });
    var reset = function () {
        setFragments(defaultFragments);
        setIsWriteLoading(true);
        // Reset the error state
        setError(null);
    };
    useEffect(function () {
        setIsMounted(true);
        var source = axios.CancelToken.source();
        var baseURL = NETWORK === "testnet"
            ? "https://squires.testnet.knightsoftheether.com"
            : "https://squires-api.knightsoftheether.com";
        var fetchFragments = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response_1, newResponse_1, updatedFragments, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, axios.post("".concat(baseURL, "/claimfragments"), { token: jwtToken }, { cancelToken: source.token })];
                    case 1:
                        response_1 = _a.sent();
                        return [4 /*yield*/, axios.get("".concat(baseURL, "/fragments/").concat(wallet), {
                                cancelToken: source.token,
                            })];
                    case 2:
                        newResponse_1 = _a.sent();
                        updatedFragments = defaultFragments.map(function (defaultFragment) {
                            var fetchedFragment = response_1.data.find(function (fragment) { return fragment.id === defaultFragment.id; });
                            if (fetchedFragment) {
                                var usedAmount = newResponse_1.data[fetchedFragment.id];
                                var realAmount = fetchedFragment.amount - usedAmount;
                                return __assign(__assign({}, fetchedFragment), { realAmount: realAmount });
                            }
                            return defaultFragment;
                        });
                        setFragments(updatedFragments);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        if (!axios.isCancel(error_1)) {
                            console.error(error_1);
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchFragments();
        return function () {
            setIsMounted(false);
            source.cancel();
        };
    }, [jwtToken, wallet, refresh]);
    var args = useMemo(function () {
        var poolIds = [];
        var fragmentIds = [];
        var amounts = [];
        var signatures = [];
        selectedFragments.forEach(function (_a) {
            var id = _a.id, amount = _a.amount, signature = _a.signature;
            var fragmentInfo = fragmentData.find(function (fragment) { return fragment.id === id; });
            if (fragmentInfo) {
                poolIds.push(fragmentInfo.poolId);
                fragmentIds.push(id);
                amounts.push(amount);
                signatures.push(signature);
            }
        });
        return [poolIds, fragmentIds, amounts, signatures];
    }, [selectedFragments]);
    var _g = useWriteContract(), writeContract = _g.writeContract, hash = _g.data, isPending = _g.isPending;
    var depositItemsContractCall = function (_a) {
        var poolIds = _a.poolIds, fragmentIds = _a.fragmentIds, amounts = _a.amounts, signatures = _a.signatures;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, writeContract({
                            address: contracts.fragments,
                            abi: FragmentsAbi,
                            functionName: "claimFragments",
                            args: [poolIds, fragmentIds, amounts, signatures],
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var _h = useWaitForTransactionReceipt({
        hash: hash,
    }), isConfirming = _h.isLoading, isSuccess = _h.isSuccess;
    var handleClaimButtonClick = function (selectedFragments) { return __awaiter(void 0, void 0, void 0, function () {
        var args;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    args = selectedFragments.reduce(function (acc, _a) {
                        var id = _a.id, amount = _a.amount, signature = _a.signature;
                        var fragmentInfo = fragmentData.find(function (fragment) { return fragment.id === id; });
                        if (fragmentInfo) {
                            acc.poolIds.push(fragmentInfo.poolId);
                            acc.fragmentIds.push(id);
                            acc.amounts.push(amount);
                            acc.signatures.push(signature);
                        }
                        return acc;
                    }, { poolIds: [], fragmentIds: [], amounts: [], signatures: [] } // This is now explicitly typed as ContractArgs
                    );
                    return [4 /*yield*/, depositItemsContractCall(args)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        error: error,
        isError: !!error,
        isSuccess: isSuccess,
        fragments: fragments,
        isWriteLoading: isWriteLoading,
        reset: reset,
        handleClaimButtonClick: handleClaimButtonClick,
        state: state,
        dispatch: dispatch,
    };
};
