import React, { createContext, useContext, useEffect, useState, } from "react";
import { addModalEventListener, removeModalEventListener, } from "../events/modalEvents";
import { isTreasureAuthHashAvailable } from "@/auth/treasure";
var ModalContext = createContext({
    isAuthModalOpen: false,
    isBridgeGearModalOpen: false,
    isInitiationModalOpen: false,
    isTreasureAuthModalOpen: false,
    openAuthModal: function () { },
    openBridgeGearModal: function () { },
    openInitiationModal: function () { },
    closeAuthModal: function () { },
    closeBridgeGearModal: function () { },
    closeTreasureAuthModal: function () { },
    closeInitiationModal: function () { },
    isCrystalClicked: false,
    setIsCrystalClicked: function () { },
    showOverlay: false,
    setShowOverlay: function () { },
});
export var useModalContext = function () {
    return useContext(ModalContext);
};
export var ModalContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isAuthModalOpen = _b[0], setIsAuthModalOpen = _b[1];
    var _c = useState(false), isBridgeGearModalOpen = _c[0], setIsBridgeGearModalOpen = _c[1];
    var _d = useState(false), isInitiationModalOpen = _d[0], setIsInitiationModalOpen = _d[1];
    var _e = useState(false), isTreasureAuthModalOpen = _e[0], setIsTreasureAuthModalOpen = _e[1];
    var _f = useState(false), isCrystalClicked = _f[0], setIsCrystalClicked = _f[1];
    var _g = useState(false), showOverlay = _g[0], setShowOverlay = _g[1];
    var openAuthModal = function () { return setIsAuthModalOpen(true); };
    var closeAuthModal = function () { return setIsAuthModalOpen(false); };
    var openBridgeGearModal = function () { return setIsBridgeGearModalOpen(true); };
    var closeBridgeGearModal = function () { return setIsBridgeGearModalOpen(false); };
    var openInitiationModal = function () { return setIsInitiationModalOpen(true); };
    var closeInitiationModal = function () { return setIsInitiationModalOpen(false); };
    var openTreasureAuthModal = function () { return setIsTreasureAuthModalOpen(true); };
    var closeTreasureAuthModal = function () { return setIsTreasureAuthModalOpen(false); };
    var contextValue = {
        isAuthModalOpen: isAuthModalOpen,
        isBridgeGearModalOpen: isBridgeGearModalOpen,
        isInitiationModalOpen: isInitiationModalOpen,
        isTreasureAuthModalOpen: isTreasureAuthModalOpen,
        openAuthModal: openAuthModal,
        closeAuthModal: closeAuthModal,
        openBridgeGearModal: openBridgeGearModal,
        closeBridgeGearModal: closeBridgeGearModal,
        openInitiationModal: openInitiationModal,
        closeInitiationModal: closeInitiationModal,
        openTreasureAuthModal: openTreasureAuthModal,
        closeTreasureAuthModal: closeTreasureAuthModal,
        isCrystalClicked: isCrystalClicked,
        setIsCrystalClicked: setIsCrystalClicked,
        showOverlay: showOverlay,
        setShowOverlay: setShowOverlay,
    };
    useEffect(function () {
        if (isTreasureAuthHashAvailable()) {
            setIsTreasureAuthModalOpen(true);
        }
    }, []);
    useEffect(function () {
        var listener = function (event) {
            var _a;
            if ((_a = event.detail) === null || _a === void 0 ? void 0 : _a.isOpen) {
                var modal = event.detail.modal;
                if (modal === "AuthModal") {
                    openAuthModal();
                }
                else if (modal === "BridgeGearModal") {
                    openBridgeGearModal();
                }
                else if (modal === "InitiationModal") {
                    openInitiationModal();
                }
                else {
                    throw new Error("ModalEvent: unsupported modal name '".concat(event.detail.modal, "'"));
                }
            }
        };
        addModalEventListener(listener);
        return function () {
            removeModalEventListener(listener);
        };
    }, []);
    return (React.createElement(ModalContext.Provider, { value: contextValue }, children));
};
