import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { Modal } from "../Modal";
import { ConnectWalletButton } from "../auth/ConnectWalletButton";
export var AuthModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, contentProps: {
            maxWidth: "300px",
        } },
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: 3 },
            React.createElement(ConnectWalletButton, { width: "100%", bg: "gray.400", color: "black" }),
            React.createElement(Button, { onClick: onClose, width: "100%", bg: "#a0aec0" },
                React.createElement(Box, { as: "span", mr: "10px" }, "Close")))));
};
